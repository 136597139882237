import '../../App.css'

import { useState } from 'react';
import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar.jsx';

import InstallRaspOs from './sub-pages/InstallRaspOs';
import AssignStaticIp from './sub-pages/AssignStaticIp';
import InstallNodejs from './sub-pages/InstallNodejs';


function ReaspberryPiSetup() {

    const [details, setDetails] = useState('raspsetup') 
    const raspberryPiOS = 'https://www.raspberrypi.com/software/'
    const raspberryPiMore = 'https://www.raspberrypi.com/documentation/computers/getting-started.html'

    return (
        <div className="App">
            <Header></Header>
            <Sidebar></Sidebar>

            <div className='things'>
                <div className="left">
                    <div className={'sub-page'} style={{ display: details === 'raspsetup' ? 'block' : 'none' }}> <InstallRaspOs></InstallRaspOs></div>
                    <div className={'sub-page'} style={{ display: details === 'assignstaticip' ? 'block' : 'none' }}> <AssignStaticIp></AssignStaticIp></div>
                    <div className={'sub-page'} style={{ display: details === 'installnodejs' ? 'block' : 'none' }}> <InstallNodejs></InstallNodejs></div>
                </div>
                <div className="right">
                    <h2>{'Environment Setup'}</h2>
                    <div className="right-sidebar-button" onClick={() => setDetails('raspsetup')}>Install the Raspberry Pi OS</div>
                    <div className="right-sidebar-button" onClick={() => setDetails('assignstaticip')}>Assign a static ip to the Pi</div>
                    <div className="right-sidebar-button" onClick={() => setDetails('installnodejs')}>Install Node.js</div>
                <br /> Find the The Raspberry Pi Imager <a className='elink' href={raspberryPiOS} target='_blank' rel="noreferrer">here</a> 
                <br /> More on the Raspberry Pi OS <a className='elink' href={raspberryPiMore} target='_blank' rel="noreferrer">here</a> 
                </div>
            </div>
        </div>
    )
}

export default ReaspberryPiSetup;