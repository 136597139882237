import { createContext, useState } from 'react'

export const AboutPageContext = createContext()

export const AboutPageContextProvider = ({ children }) => {

    const [aboutPageClassName, setAboutPageClassName] = useState('modalClose')

    return (
        <AboutPageContext.Provider value={{
            aboutPageClassName,
            setAboutPageClassName
        }}>
            {children}
        </AboutPageContext.Provider>
    )
}

export default AboutPageContext