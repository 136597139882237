import React from 'react'

function Sound() {

    return (
        <div>
            <h3>Play a sound file</h3>
        </div>
    )
}

export default Sound