
import '../../App.css'
import routes from './routes.json'
import { useNavigate } from 'react-router-dom'


function SidebarButton({ button }) {

  const navigate = useNavigate('/')

  const pageDownHandler = () => {
    if (routes[button] === undefined) {
      navigate('/')
    }
    else {
      navigate('/' + routes[button])
    }
  }
  return (<div className="sidebar-button" onClick={pageDownHandler}>{button} </div>)
}

export default SidebarButton