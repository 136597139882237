import '../../App.css'
import './database-structure.css'

import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar.jsx';

function DatabaseStructure() {

    const nodes = require('../../assets/nodes.png')
    const list = require('../../assets/node3-list.png')
    const details = require('../../assets/node4-details.png')

    return (
        <div className="App">
            <Header></Header>
            <Sidebar></Sidebar>

            <div className='page-content'>
                <h2>{'Database Structure'}</h2>
                <div className='page-content-text'>

                    <b>Dashboards management</b> <br />
                    Node 3: Stores the list of your dashboards. <br />
                    Node 4: Stores the details of each dashboard (Buttons, Sliders, Toggles, etc).

                    <br /> <br />
                    <b>Feedback Node</b><br />
                    Node 2: Update this node from your projects to notify the mobile app that a command was successfully received.
                    
                    <br /> <br />
                    <b>Settings</b> <br />
                    Node 1: Was used to store the mobile app settings. The settings are now stored in the local storage instead.

                </div>

                <img src={nodes} className={'data-dashboards-img'} alt="" />
                <img src={list} className={'data-dashboards-img'} alt="" />
                <img src={details} className={'data-dashboards-img'} alt="" />
                <br /> Hover on an image to zoom in.
            </div>
        </div>
    )
}

export default DatabaseStructure;