import '../../App.css'

import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar.jsx';

import SubRunProjectAsService from './sub-pages/SubProjectAsService';


function RunProjectAsService() {

    const systemdServices = 'https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units'
    const debianDystemdServices = 'https://wiki.debian.org/systemd'
    const archiLinuxsystemdServices = 'https://wiki.archlinux.org/title/Systemd'

    return (
        <div className="App">
            <Header></Header>
            <Sidebar></Sidebar>

            <div className='things'>
                <div className="left">
                    <div className={'sub-page'} >
                        <SubRunProjectAsService></SubRunProjectAsService>
                    </div>
                </div>

                <div className="right">
                    <h2>systemd</h2>
                    <div className="right-sidebar-button">
                        Find more on systemd services:
                        <br /><br />
                        <a href={systemdServices} target="_blank" rel="noreferrer">
                            systemd services
                        </a>
                         <br /><br />
                        <a href={debianDystemdServices} target="_blank" rel="noreferrer">
                            debian
                        </a>

                        <br /><br />
                        <a href={archiLinuxsystemdServices} target="_blank" rel="noreferrer">
                            archlinux
                        </a>

                       
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RunProjectAsService;