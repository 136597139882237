import { useState } from 'react';
import '../../App.css'

import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar.jsx';

function TemplateProject() {

    const alien = require('../../assets/alien.png')
    const folderStructure = require('./assets/folder-structure.png')
    const firebaseconfig = require('./assets/firebase-config.png')
    const scp = require('./assets/scp.png')

    const [section, setSection] = useState('projectsetup')

    return (
        <div className="App">
            <Header></Header>
            <Sidebar></Sidebar>
            <div className={'things'}>
                <div className="left">
                    <div>
                        <img className='smalien-img' src={alien} alt="" />
                        Here's a Project Starter Template to get started.
                    </div>

                    <div style={{ display: section === 'projectsetup' ? 'block' : 'none', paddingLeft: 10 }}>
                        <h3>Project Setup</h3>
                        <ol>
                            <li>Download the template from https://github.com/Sisko16/guimaker-template (will be available soon) </li>
                            <li>Rename the template-project folder to your project name</li>
                            <li>From the Firebase console, copy your database config to the firebase-config.json file</li>
                            <li>Edit the environment variables in dash.env</li>
                            <li>Use the <b>'scp'</b>  command to copy the project folder to the Raspberry Pi</li>
                            <li>Run the <b>setup.sh</b> script to complete the setup</li>
                        </ol>
                        <h3>Copy the project folder to the Raspberry Pi </h3>
                        Let's say you want to copy your project from the 'guimaker' folder on your pc to the '/home/pi' folder on the Pi.
                        <br />With the Pi for instance:
                        <ul>
                            <li>ip address: 10.0.0.50</li>
                            <li>username: 'pi'</li>
                            <li>the project folder name is 'led'</li>
                        </ul>
                        Open a terminal, run the following command then enter the password when prompted to 
                        <div className="code-snippet">
                            scp -r led pi@10.0.0.50:/home/pi
                        </div>
                        <img src={scp} height={250} alt="" />
                        <br /><br />

                        <h3>Run setup.sh </h3>
                        In a terminal window run:
                        <div className="code-snippet">
                            bash setup.sh
                        </div>
                        The script performs the following tasks:
                        <ul>
                            <li>Set the project name</li>
                            <li>Set the project description</li>
                            <li>Install Firebase</li>
                            <li>Add executable permissions to dash.js</li>
                            <li>Add the project folder to dash.env</li>
                            <li>Rename the dash.* files to {'<project-name>.*'}</li>
                            <li>replace 'dash' with the project name in packge.json, package-lock.json</li>
                            <li>Wrap the firebase-config.json keys with double quotes</li>
                            <li>Edit the *.service file</li>
                            <li>Move the service file to the etc/systemd/system folder</li>
                            <li>Enable and start the service</li>
                            <li>Check the service status</li>
                        </ul>

                    </div>
                    <div style={{ display: section === 'folderstructure' ? 'block' : 'none', paddingLeft: 10 }}>
                        <h3>Folder Structure</h3>
                        The template includes the following files.
                        <ul>
                            <li>{'app > app.js'}</li>
                            <li>{'dash.env'}</li>
                            <li>{'dash.js'}</li>
                            <li>{'dash.service'}</li>
                            <li>{'firebase-config.json'}</li>
                        </ul>
                        <img className='project-folder-img' src={folderStructure} alt="" />

                        <br /> The files and folders <b>setup.sh</b>, <b>images</b>, <b>python</b> are only needed during the setup.
                    </div>

                    <div style={{ display: section === 'appjs' ? 'block' : 'none', paddingLeft: 10 }}>
                        <h3>app.js</h3>
                        This file contains the code responsible for acting on devices and reading telemetry data.
                        <br /> Import this file into <b>dash.js</b>

                        <div className="code-snippet">
                            <pre>
                                {`export const process_data = (data) => {
    // Actions
}`}</pre> </div>
                        <br />
                        Here's an example of an <b>app.js</b> code taken from the demo project "Switch a led on/off"
                        <div className="code-snippet">
                            <pre>
                                {`/***
Switch a led on/off
***/
import {SerialPort} from 'serialport'

const usbPort = process.env.ARDUINO_USB_PORT;
const port = new SerialPort({path:'/dev/' + usbPort, baudRate:9600});
                                
export const process_data = (data) => {

    const led_state = (data.val().payload) ? 'on' : 'off'
    port.write(led_state, (error) => {
        console.log (error)
        return
    });

    port.on('error', (error) => { 
        console.log (error)
        return
    });
}`}
                            </pre>
                        </div>
                    </div>

                    <div style={{ display: section === 'dashenv' ? 'block' : 'none', paddingLeft: 10 }}>
                        <h3>dash.env</h3>
                        Use environment variables to run a project as a service.
                        <br />The project template uses the following as environment variables.
                        <ul>
                            <li>A Firebase user email and password</li>
                            <li>The dashboard name the project's app will listen to</li>
                        </ul>
                        Add your own variables to dash.env if necessary.

                        <br /> <br /> An example of a dash.env
                        <div className="code-snippet">
                            <pre>
                                {`EMAIL=jdoe@robot.io
PASSWORD=robot_password
DASHBOARD_NAME=Robot
FIREBASE_CONFIG=/etc/pi/led/webconfig.json`}
                            </pre>
                        </div>
                        The FIREBASE_CONFIG variable will be automatically added by the setup.sh bash
                    </div>

                    <div style={{ display: section === 'dashjs' ? 'block' : 'none', paddingLeft: 10 }}>
                        <h3>dash.js</h3>
                        This is the entry point to your project. It does the following:
                        <ul>
                            <li>Import libraries and the app.js module</li>
                            <li>Declare constants and variables</li>
                            <li>Initialize Firebase</li>
                            <li>Authenticate to Firebase</li>
                            <li>Reset the feedback node</li>
                            <li>Send a feedback</li>
                            <li>Process incoming data using the app.js module</li>
                        </ul>
                        The section "Dash.js in Details" will go into more details about dash.js
                    </div>

                    <div style={{ display: section === 'dashservice' ? 'block' : 'none', paddingLeft: 10 }}>
                        <h3>dash.service</h3>
                        One of the benefits of running a project as a <b>systemd</b> service is to ensure the
                        project is always available, even in the event of a Pi reboot.
                        <br /><br />
                        <b>setup.sh</b> will automatically configure, enable, and start the service
                        <div className="code-snippet">
                            <pre>
                                {`[Unit] 
Description=Put your project's description here

[Service]
ExecStart=/usr/bin/node  path2dash.js &
 
SyslogIdentifier=dash
User=user-name
EnvironmentFile=path2dash.env

[Install] 
WantedBy=multi-user.target`}
                            </pre>
                        </div>
                    </div>

                    <div className='sub-page' style={{ display: section === 'webconfigjson' ? 'block' : 'none' }}>
                        <h3>firebase-config.json</h3>
                        Copy the key value pairs (the curly braces and everything in between) to the file
                        <br />The setup.sh script will edit the keys to a valid json format.

                        <img className='project-folder-img' width={500} height={300} src={firebaseconfig} alt="" />

                        <br /> The firebase-config.json keys edit to a valid json format
                        <div className="code-snippet">
                            <pre>
                                {`{ 
    "apiKey": "AIzaSyAKhNMUlUAleIjXx-RQlaI7ADCzebggvhw",
    "authDomain": "tempo-20911.firebaseapp.com",
    "databaseURL": "https://tempo-20911.firebaseio.com",
    "projectId": "tempo-20911",
    "storageBucket": "tempo-20911.appspot.com",
    "messagingSenderId": "791883765016",
    "appId": "1:791883765016:web:47d6e915892c571feffa6d"
}`}
                            </pre>
                        </div>


                    </div>
                </div>
                <div className="right">
                    <h2>{'Template Project'}</h2>
                    <div className="right-sidebar-button" onClick={() => setSection('projectsetup')}>Project setup - setup.sh</div>
                    <div className="right-sidebar-button" onClick={() => setSection('folderstructure')}>Folder Structure</div>
                    <div className="right-sidebar-button" onClick={() => setSection('appjs')}>app.js</div>
                    <div className="right-sidebar-button" onClick={() => setSection('dashenv')}>dash.env</div>
                    <div className="right-sidebar-button" onClick={() => setSection('dashjs')}>dash.js</div>
                    <div className="right-sidebar-button" onClick={() => setSection('dashservice')}>dash.service</div>
                    <div className="right-sidebar-button" onClick={() => setSection('webconfigjson')}>firebase-config.json</div>

                </div>
            </div>

        </div>
    )
}

export default TemplateProject;