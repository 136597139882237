import React from 'react'

// const ledCam = require('./assets/led-cam.png')
const officeLight = require('../assets/office-light.png')
const kitchen = require('./assets/kitchen.png')

function AboutProjects() {

    return (
        <div>
            <h3>Demo projects</h3>
            Each project starts with a brief overview, followed by the hardware used, the hardware setup, 
            <br /> A same dashboard can be used to control multiple devices.
            <br /> A single toggle for instance can be used to switch on/off lights in different locations.
            <br />
            <br /> <img src={kitchen} width={250} height={480} style={{padding:10}} alt="" />
            <img src={officeLight} width={250} height={480} style={{padding:10}} alt="" />
        </div>
    )
}

export default AboutProjects