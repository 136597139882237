import '../../App.css'
import './dashboards-details.css'

import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar.jsx';


function DashboardsDetails() {

    const dashboard = require('../../assets/robot.png')
    const details = require('../../assets/node4-details.png')

    return (
        <div className="App">
            <Header></Header>
            <Sidebar></Sidebar>
            <div className='page-content'>
                <h2>{'Dashboard Controls'}</h2>
                <div className='page-content-text'>
                    <b>Control name</b> and <b>Payload</b> (in addition to the dashboard name) are the only fields you will need
                    <br/>in your projects.
                </div> 

                <div className='property-container'>
                    <div>
                        <div className='help' onClick={() => {}}> Button</div>
                        <ul>
                            <li>Control name</li>
                            <li>Payload</li>
                            <li>Control type</li>
                            <li>State</li>
                        </ul>
                    </div>

                    <div>
                        <div className='help' onClick={() => {}}> Slider</div>
                        <ul>
                            <li>Control name</li>
                            <li>Payload</li>
                            <li>Control type</li>
                            <li>Payload min</li>
                            <li>Payload max</li>
                        </ul>
                    </div>

                    <div>
                        <div className='help' onClick={()=>{}}> Text</div>
                        <ul>
                            <li>Control name</li>
                            <li>Payload</li>
                            <li>Control type</li>
                        </ul>
                    </div>

                    <div>
                        <div className='help' onClick={() => {}}> Toggle</div>
                        <ul>
                            <li>Control name</li>
                            <li>Payload</li>
                            <li>Control type</li>
                        </ul>
                    </div>
                </div>
                
                <img src={dashboard} className={'dash-img'} alt="" />
                <img src={details} className={'detail-img'} alt="" />
            </div>

        </div>)
}

export default DashboardsDetails;