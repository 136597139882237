import '../../App.css'
import './header.css'

import { useContext, useState } from 'react';
import AboutPageContext from '../../context/AboutPageContext';
import AboutPage from '../../pages/AboutPage/AboutPage.js';
import SlidingWindow from '../sliding-window/SlidingWindow.js'

function Header({}) {
    const { setAboutPageClassName } = useContext(AboutPageContext)
    const [isPageVisible, setPageVisible] = useState(false);

    const togglePage = () => {
        setPageVisible(!isPageVisible);
    }

    return (
        <>
            <header className="App-header" >
                <div className='header-button' onClick={togglePage}>About</div>
            </header>
            <SlidingWindow isVisible={isPageVisible}>
                <AboutPage togglePage={togglePage}></AboutPage>
            </SlidingWindow>
        </>
    )

}

export default Header