import '../../App.css'

import SidebarButton from './SidebarButton'
import sidebar from './sidebar.json'

function Sidebar() {
  
  return (
    <div className="App">
      <div className={'sidebar'}>
        {sidebar.buttons.map(button => <SidebarButton
          key={sidebar.buttons.indexOf(button)} button={button}></SidebarButton>)}
      </div>
    </div>
  )
}

export default Sidebar