import React from 'react'
import '../project.css'

const webcam = require('../assets/webcam.png')
const storage = require('../assets/storage.png')


const camera = require('./assets/camera.png')
const cameraEdit = require('./assets/camera-edit.png')
const cameraSettingsEmpty = require('./assets/camera-settings-empty.png')
const cameraSettings = require('./assets/camera-settings.png')
const cameraDashboard = require('./assets/camera-dashboard.png')


function Camera() {

  return (
    <div>
      <h3>Display a picture to your dashboard</h3>
      Display to your dashboard a picture taken by a remote webcam/camera
      <br /><br />

      <img src={cameraDashboard} className={'project-mobile-img'} alt="" />
      <img src={webcam} className={'project-img-2nd'} alt="" />
      <br /><br />

      <b>Project download: </b> https://github.com/Sisko16/camera.git
      <br />
      <h4>Hardware used</h4>
      <ul>
        <li> A Raspberry Pi 2 model B+ / Pi 3 B+</li>
        <li> A webcam (Logitech)</li>
        <li> A WiFi adapter (Edimax) for the Pi 2 model B+</li>
      </ul>

      <h4>Hardware setup</h4>
      Connect the webcam to a usb port on the Raspberry Pi
      <img src={webcam} className={'project-img'} alt="" />
      <br /> <br />

      <h4>Add a picture placeholder to your dashborad</h4>
      Go to the dashboard where to add the placeholder, 
      tap the build icon to enter the edit mode.
      <br />
      <br /><img src={camera} className={'project-mobile-img'} alt="" />

      <br /> <br /> Next, tap the camera icon to setup your camera.
      <ul>
        <li>Enter the settings</li>
        <li>Enable the camera</li>
        <li>Save and navigate back to your dashboard</li>
      </ul>

      <img src={cameraEdit} className={'project-mobile-img'} alt="" />
      <img src={cameraSettingsEmpty} className={'project-mobile-img'} alt="" />
      <img src={cameraSettings} className={'project-mobile-img'} alt="" />
      <img src={cameraDashboard} className={'project-mobile-img'} alt="" />

      <br /><br />
      <h4>Fields description</h4>
      
      <ul>
        <li>Picture folder: The folder in Firebase where a link to the picture will be saved</li>
        <li>The camera timeout: Time after which the mobile will stop fetching the picture</li>
        <li>Uncheck "Enable Camera" if you want to prevent the picture from being displayed to the dashboard </li>
      </ul>
      Go to Storage in the Firebase console to create the folder were to upload a picture
      <img src={storage} height={180} width={750} alt="" />

      <br />
      ---
    </div>
  )
}

export default Camera