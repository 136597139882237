import React from 'react'

function AssignStaticIp() {

  const dnsserver = require('../assets/dns-server.png')
  const routerip = require('../assets/router-ip.png')
  const staticip = require('../assets/static-ip.png')
  const staticipDropdown = require('../assets/static-ip-dropdown.png')
  const remoteSSH = require('../assets/remote-ssh.png')

  return (
    <>
      <h3>Assign a static ip address to the Rapsbberry Pi</h3>

      The goal is to garantee access to the Raspberry Pi by keeping a same ip address over the time.
      <br />
      <br /> Login to your Raspberry Pi

      <ol>
        <li>Get your router ip address</li>
        <li>Get your dns server ip address</li>
        <li>Configure a static ip address for the Raspberry Pi</li>
        <li>Reboot the Raspberry Pi and SSH from a remote computer</li>
      </ol>

      <h4>1 Get your router ip address</h4>
      In a terminal run: ip r
      <br /> Next to "default via" is your router ip address, it might be different from the one shown here.
      <img src={routerip} alt="" />  <br /><br />

      <h4>2 Get your dns server ip address</h4>
      In a terminal run: grep "nameserver" /etc/resolv.conf
      <br /> Next to "nameserver" is your dsn server ip address.
      <img src={dnsserver} alt="" /> <br /><br />

      <h4>3 Configure a static ip address for the Raspberry Pi</h4>
      Right click on the wireless icon on the top right of the screen then click on
      "wireless & Wired Network Settings"
      <br />
      <img src={staticipDropdown} alt="" />  <br />
      <br /> <br />
      In the "Network Preferences" window:
      <ol>
        <li>Set Configure to "Interface" and "wlan0"</li>
        <li>Uncheck "Automatically Configure empty options"</li>
        <li>Check "Disable IPv6"</li>
        <li>Enter your desired Static address followed by /24 {'(the default Subnet Mask )'}</li>
        <li>Enter your router ip address</li>
        <li>Enter your dns server ip address</li>
        <li>Click Apply and Close</li>
      </ol>
      <img src={staticip} alt="" />  <br />
      <br />
      <h4>4 Reboot the Raspberry Pi and SSH to the Pi from a remote computer</h4>
      This last step is to insure the setup was successfull and that you can remotely
      access the Rapsberry Pi.
      <img src={remoteSSH} alt="" />  <br /><br />
      <br />
    </>
  )
}

export default AssignStaticIp 