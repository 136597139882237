import '../../App.css'

import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar.jsx';

function GuideQuickIntro() {

    const alien = require('../../assets/alien.png')
    const list = require('../../assets/dashboards-list.png')
    const garage = require('../../assets/garage.png')
    const office = require('../../assets/office-light.png')

    const numberOfSpaces1 = 65
    const numberOfSpaces2 = 26
    const spaces1 = '\u00A0'.repeat(numberOfSpaces1);
    const spaces2 = '\u00A0'.repeat(numberOfSpaces2);

    return (
        <div className="App">
            <Header></Header>
            <Sidebar></Sidebar>

            <div className='page-content'>
                <h2>{"Project Guide for the GUI Maker app"}</h2>
                <div className='page-content-text'>
                    This guide covers several key topics to help you get started with developing projects 
                    <br />to control devices remotely using the GUI Maker mobile app.

                    <br /> <br />
                    Click the "About" button for more details
                </div>

                <br />  <b>{spaces1}  List of Dashboards {spaces2} Dashboard details</b>

                <br />
                <img src={alien} className={'home-alien-img'} alt="" />
                <img src={list} className={'home-img'} alt="" />
                <img src={garage} className={'home-img'} alt="" />
                <img src={office} className={'home-img'} alt="" />
            </div>
        </div>
    )
}

export default GuideQuickIntro;