import '../App.css'
import './project.css'

import { useState } from 'react';

import Header from '../components/header/Header';
import Sidebar from '../components/sidebar/Sidebar.jsx';
import projects_list from './project-list.json'

import Led from './led/Led'
import Camera from './camera/Camera'
import Sound from './sound/Sound'
import AboutProjects from './AboutProjects'


function DemoProjects() {

    const project_names = Object.values(projects_list);
    const [project, setProject] = useState('aboutprojects')

    return (
        <div className="App">
            <Header></Header>
            <Sidebar></Sidebar>

            <div className='things'>
                <div className="left">
                    <div className={'sub-page'} style={{ display: project === 'aboutprojects' ? 'block' : 'none' }}> 
                       <AboutProjects></AboutProjects></div>
                    <div className={'sub-page'} style={{ display: project === 'led' ? 'block' : 'none' }}> 
                       <Led></Led></div>
                    <div className={'sub-page'} style={{ display: project === 'camera' ? 'block' : 'none' }}> 
                       <Camera></Camera></div>
                    <div className={'sub-page'} style={{ display: project === 'sound' ? 'block' : 'none' }}> <Sound></Sound></div>
                </div>

                <div className="right">
                     <div className='right-sidebar-button' onClick={() => setProject('aboutprojects')}> Demo projects </div>
                    <h2>{'Demo Projects List'}</h2>
          
                    {project_names.map(project_name => <div className='right-sidebar-button' onClick={() => setProject(project_name.project)}> {project_name.title} </div>)}
                   
                </div>
            </div>
        </div>
    )
}

export default DemoProjects;