import React from 'react'

function SubProjectAsService() {

  return (
    <>
      <h2>Run a Project as a Service</h2>
      <b>setup.sh</b> will create and start the service automatically.
      <br /> But here's what to do if your were to ceate and start the service manually.
      <br /><br />
      Let's say the path to your project folder is &nbsp;<b>{'/home/pi/light/'}</b>&nbsp;, you renamed the
      &nbsp;<b>{`dash.*`}</b>&nbsp; files to  &nbsp;<b>{`light.*`}</b>&nbsp;, 
      <br /> the user name is <b>pi</b>.

      <ol>
        <li>Add executable permission to light.js</li>
        <li>Edit the light.service file</li>
        <li>Move the light.service file to the system folder</li>
        <li>Enable the service to start at boot</li>
        <li>Start the service</li>
      </ol>
      <pre>

        <h5>1 Add executable permission to light.js</h5>
        <div className="code-snippet">sudo chmod +x light.js</div>
        <br />
        <h5>2 Create service to run light.js</h5>
        Edit light.service and replace what's shown in bold
        <div className="code-snippet">
          [Unit] <br />
          Description=<b>Put your project's description here</b>
          <br /><br />

          [Service]<br />
          ExecStart=/usr/bin/node <b>/home/pi/light/light.js</b>  &
          <br />
          <br />SyslogIdentifier=<b>light</b>
          <br />User=<b>pi</b>
          <br />EnvironmentFile=<b>/home/pi/light/light.env</b>

          <br /><br />
          [Install] <br />
          WantedBy=multi-user.target
        </div>

        <br />
        <h5>3 Move the light.service file to system folder</h5>
        <div className="code-snippet">sudo mv ligth.service /etc/systemd/system</div>

        <br />
        <h5>4 Enable the service to start at boot</h5>
        <div className="code-snippet">sudo systemctl enable light.service</div>

        <br />
        <h5>5 Start the service</h5>
        <div className="code-snippet">sudo systemctl start light.service </div>
      </pre>
      ---
    </>
  )
}

export default SubProjectAsService