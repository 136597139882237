import React from 'react'

function InstallNodejs() {
  const curl = require('../assets/curl.png')

  return (
    <>
      <h3> Install Node.js </h3>
      On the Raspberry Pi run:
      <pre>
        <div className="code-snippet">
          sudo apt-get update<br />
          sudo apt-get dist-upgrade<br /> <br />

          curl -sL https://deb.nodesource.com/setup_MAJORVERSION.x | sudo -E bash - <br />
          sudo apt-get install -y nodejs
        </div>
        Verify that node was successfully installed by running: node -v
      </pre><br />
      In the curl command, replace <b>MAJORVERSION</b>  by the Node js 's major version
      (see   <a href="https://nodejs.org/en/" target='_blank' rel="noreferrer">https://nodejs.org/en/)</a>
      <br /> Let's say that the current LTS node version is 23.15.1, then replace MAJORVERSION by 23
      <br />
      <img src={curl} width={700} alt="" />
      <br /> <br />
    </>

  )
}

export default InstallNodejs