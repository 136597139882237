import '../../App.css'

import { useState } from 'react';
import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar.jsx';


function DashjsInDetails() {
    
    const alien = require('../../assets/alien.png')
    const feedback = require('../../assets/robot-feedback.png')
    const user = require('../../assets/add-user.png')
    const [code, setCode] = useState(0)

    const dimports = 'Import libraries and the app.js module.'

    const ddeclarations = `Read the environment variables from dash.env and declare the nodes. 

DASHBOARD_NODE: The script listens to this node for incoming data.
FEEDBACK_NODE: This node is used to notify the mobile app data was received.
`
    const dinitialize_firebase = `Read the app's Firebase configuration and connect to the Firebase database.`

    const dauthenticate_firebase = `Authenticate with an existing user's email and password.
Here's where to create one.`

    const dreset_feedbacknode = `Reset the feedback node when the script is the first time loaded.`
    
    const dsend_feedback = `Send a feedback to the mobile app when data is received.
A bolt on the top left of a dashboard confirms data was received.`
    
    const dlistento_dashboard = 'Listen to and process incoming data by calling the process_data method.'

    const dprint_to_console = ''

const imports = `import fs from 'fs' 
import {initializeApp} from 'firebase/app'; 
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'; 
import {getDatabase, ref, set, onChildChanged} from 'firebase/database';
import {process_data} from './app/app.js'`

const declarations = `const EMAIL = process.env.EMAIL;
const PASSWORD = process.env.PASSWORD;
const DASHBOARD_NAME = process.env.DASHBOARD_NAME;
const WEBCONFIG_JSON = process.env.WEBCONFIG_JSON;

const DASHBOARD_NODE = '4/' + DASHBOARD_NAME;
const FEEDBACK_NODE = '/2';
let status = 0;`

const initialize_firebase = `const wc = fs.readFileSync(WEBCONFIG_JSON);
const webConfig = JSON.parse(wc);
const app = initializeApp(webConfig);
    
const firebase = getDatabase()
const auth = getAuth();`

const authenticate_firebase = `signInWithEmailAndPassword(auth, EMAIL, PASSWORD)
   .then((userCredential) => {
       listento_dashboard();
   })
   .catch((error) => {
       const errorCode = error.code;
       const errorMessage = error.message;
       console.log(error.message)
});`

const reset_feedbacknode = `const reset_feedbacknode = () => {
   set(ref(firebase, FEEDBACK_NODE), {
      "controlName": '',
      "dashboard": '',
      "status": ''
   });
}`

const send_feedback = `const send_feedback = (data) => {
    let controlName = data.val().controlName;
    status = (status == 0) ? 1 : 0 // Forces feedback node update               
    
    set(ref(firebase, FEEDBACK_NODE), {
      "controlName": controlName,
      "dashboard": DASHBOARD_NAME,
      "status": status
    });
 }`

const listento_dashboard = `const listento_dashboard = () => {
   const dashboard_ref = ref(firebase, DASHBOARD_NODE);
   reset_feedbacknode()
   console.log("Listening to: " + DASHBOARD_NAME);

   onChildChanged((dashboard_ref), (data) => {

     if (data.val().controlName === process.env.LED_SWITCH_LABEL) {
        process_data(data);
     }
     print_to_console(data)

     //Delaying the feedback on Raspberry Pi 3 B+ and latest
     setTimeout(function () {
        send_feedback(data);
     }, 3000);
   });
}`

const print_to_console = `const printto_console = (data) => {
                                
    var dashboard = data.ref.parent.key;  // Get the dashboard name
    var controlName = data.val().controlName;
    var payload = data.val().payload;
    var type = data.val().type;
                                
    console.log("---");
    console.log(" Dashboard: " + dashboard);
    console.log(" Control:   " + controlName);
    console.log(" Type:      " + type);
    console.log(" payload:   " + payload);
}`

    return (
        <div className="App">
            <Header></Header>
            <Sidebar></Sidebar>

            <div className='things'>
                <div className="left">
                    <div>
                        <img className='smalien-img' src={alien} alt="" />
                        I use the code below as the main script in projects.
                    </div>

                    <div className="code-snippet" style={{ display: code === 0 || code === 1 ? 'block' : 'none' }}>
                        <h3>Imports</h3>
                        <pre>{imports}</pre>
                    </div>
                    <div style={{ display: code === 1 ? 'block' : 'none' }}>
                        <pre>{dimports}</pre>
                    </div>

                    <div className="code-snippet" style={{ display: code === 0 || code === 2 ? 'block' : 'none' }}>
                        <h3>Declarations</h3>
                        <pre>{declarations}</pre>
                    </div>
                    <div style={{ display: code === 2 ? 'block' : 'none' }}>
                        <pre>{ddeclarations}</pre>
                    </div>

                    <div className="code-snippet" style={{ display: code === 0 || code === 3 ? 'block' : 'none' }}>
                        <h3>Initialize Firebase</h3>
                        <pre>{initialize_firebase}</pre>
                    </div>
                    <div style={{ display: code === 3 ? 'block' : 'none' }}>
                        <pre>{dinitialize_firebase}</pre>
                    </div>

                    <div className="code-snippet" style={{ display: code === 0 || code === 4 ? 'block' : 'none' }}>
                        <h3>Authenticate Firebase</h3>
                        <pre>{authenticate_firebase}</pre>
                    </div>
                    <div style={{ display: code === 4 ? 'block' : 'none' }}>
                        <pre>{dauthenticate_firebase}</pre>
                        <img src={user} width = {700} alt="" />
                    </div>

                    <div className="code-snippet" style={{ display: code === 0 || code === 5 ? 'block' : 'none' }}>
                        <h3>Reset the feedback node</h3>
                        <pre>{reset_feedbacknode} </pre>
                    </div>
                    <div style={{ display: code === 5 ? 'block' : 'none' }}>
                        <pre>{dreset_feedbacknode} </pre>
                    </div>

                    <div className="code-snippet" style={{ display: code === 0 || code === 7 ? 'block' : 'none' }}>
                        <h3>Send a feedback to the mobile app</h3>
                        <pre>{send_feedback}</pre>
                    </div>
                    <div style={{ display: code === 7 ? 'block' : 'none' }}>
                        <pre>{dsend_feedback}</pre>
                        <img className='home-img'  src={feedback} alt="" />
                    </div>

                    <div className="code-snippet" style={{ display: code === 0 || code === 6 ? 'block' : 'none' }}>
                        <h3>Listen & Process incoming data</h3>
                        <pre>{listento_dashboard}</pre>
                    </div>
                    <div style={{ display: code === 6 ? 'block' : 'none' }}>
                        <pre>{dlistento_dashboard}</pre>
                    </div>

                    <div className="code-snippet" style={{ display: code === 0 || code === 8 ? 'block' : 'none' }}>
                        <h3>Print to console</h3>
                        <pre>{print_to_console}</pre>
                    </div>
                    <div style={{ display: code === 8 ? 'block' : 'none' }}>
                        <pre>{dprint_to_console}</pre>
                    </div>

                </div>

                <div className="right">

                    <h2>{'dash.js in details'}</h2>
                    <div className="right-sidebar-button" onClick={() => setCode(0)}>Display all sections</div>
                    <br /> Sections walkthrough
                    <div className="right-sidebar-button" onClick={() => setCode(1)}>Imports</div>
                    <div className="right-sidebar-button" onClick={() => setCode(2)}>Declarations</div>
                    <div className="right-sidebar-button" onClick={() => setCode(3)}>Initialize Firebase</div>
                    <div className="right-sidebar-button" onClick={() => setCode(4)}>Authenticate Firebase</div>
                    <div className="right-sidebar-button" onClick={() => setCode(5)}>Reset the Feedback Node</div>
                    <div className="right-sidebar-button" onClick={() => setCode(7)}>Send a feedback to the mobile app</div>
                    <div className="right-sidebar-button" onClick={() => setCode(6)}>Listen to / Process incoming data</div>
                </div>
            </div>
        </div>
    )
}

export default DashjsInDetails;