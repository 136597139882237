// import '../../../App.css'
import React from 'react'

function InstallRaspOs() {

  const osimager = require('../assets/os-imager.png')
  const osssh = require('../assets/os-ssh.png')
  const oswlan = require('../assets/os-wlan.png')
  const osverify = require('../assets/os-verify.png')
  const gear = require('../assets/gear.png')


  return (
    <div >
      <h3>Install Rapsberry Pi OS</h3>
      First download and run the Raspberry Pi Imager, link on the sidebar, Next.
      <br />
      Click on <img src={gear} height={20} width={20} alt="" /> <br />
      <ol>
        <li>Enable SSH</li>
        <li>Set the Pi username and password </li>
        <li>Configure the wireless network: LAN's SSID & password</li>
        <li>Write and wait until the verification is complete</li>
      </ol>

      <img src={osimager} className={'sub-page-image'} alt="" />
      <br />
      <img src={osssh} className={'sub-page-image'} alt="" />
      <br />
      <img src={oswlan} className={'sub-page-image'} alt="" />
      <br />
      <img src={osverify} className={'sub-page-image'} alt="" />
    </div>
  )
}

export default InstallRaspOs