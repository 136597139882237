import React from 'react'
import './about-page.css'

function AboutPage({ togglePage }) {

    const mobileApp = 'https://play.google.com/store/apps/details?id=com.gui4iot.guyp'
    const guide = 'https://guimaker.app/'
    // const author = 'said.chaida@gmail.com / Automation Engineer' 
    const aut = require('../../assets/dev.png')

    return (
        <div className={'about-page'}>

            <div className='about-header'>
                <h2>What’s in the Guide?</h2>
            </div>

            <div className='about-content'>
                <p>This guide covers several key topics to help you get started with your projects:</p>

                <section>
                    <h5>Understanding Data Organization in Your Firebase Database</h5>
                    <p>
                        Learn how the mobile app manages and organizes data within your Firebase database.
                        This knowledge is essential for effectively connecting devices to the app.
                    </p>
                </section>

                <section>
                    <h5>Setting Up a Raspberry Pi</h5>
                    <p>
                        Follow step-by-step instructions to set up a Raspberry Pi,
                        perfect for launching new projects or becoming acquainted with the device.
                    </p>
                </section>

                <section>
                    <h5>Project Starter Template</h5>
                    <p>
                        Utilize a ready-to-use template designed to help you quickly initiate new projects.
                    </p>
                </section>

                <section>
                    <h5>Running a Project as a systemd Service</h5>
                    <p>
                        Get guidance on configuring your project to run as a systemd service,
                        ensuring automatic startup and reliable operation.
                    </p>
                </section>

                <section>
                    <h5>Demo Walkthroughs</h5>
                    <p>
                        Access practical demonstrations to better understand and apply the concepts covered in this guide.
                    </p>
                </section>

                <p>
                    <b>Note: </b>
                    A strong understanding of the database structure provides more flexibility in developing your own solutions for new projects.
                    While this guide focuses on setting up a Raspberry Pi, the principles and steps are also applicable to other single-board computers (SBCs).
                </p>

                <hr />
                <div className='about-bio'>
                    <div className='about-bio-image'>  <img src={aut} alt="" /></div>
                    <div className='about-bio-text'>
                        Said Chaida
                        <br />Sr. QA Engineer at Firstup
                    </div>
                </div>

                <h5>Links:</h5>
                <div className='about-links'>
                    Mobile App &nbsp;
                    <a className='elink' href={guide} target='_blank' rel="noreferrer"> User Guide</a>
                </div>

                <div className='about-links'>
                    Download the App from the &nbsp;
                    <a className='elink' href={mobileApp} target='_blank' rel="noreferrer"> Play Store</a>
                </div>
            </div>

            <div className='about-footer'>
                <div className='sliding-window-button' onClick={togglePage}>Close</div>

            </div>
        </div>
    )
}

export default AboutPage