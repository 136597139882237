import React from 'react'
import '../project.css'

const leddashboard = require('./assets/led-dashboard.png')
const led = require('./assets/led-arduino.png')
const rasparduino = require('./assets/rasp-arduino.png')
const ledconnected = require('./assets/led-connected.png')

function Led() {

    return (
        <div>
            <h3>Switch on/off a led attached to an Arduino</h3>
            In this project we use the mobile app to control a led attached to an Arduino.
            <br /> the Arduino itself connected to a Raspberry Pi.
            <br /> <br />

            <img src={leddashboard} className={'project-mobile-img'} alt="" />
            <img src={ledconnected} className={'project-img-2nd'} alt="" />
            <br /><br />
            <b>Project download:</b>  https://github.com/Sisko16/led.git
            <br />

            <h4>Hardware used</h4>
            <ul>
                <li> A Raspberry Pi 2 model B+ / P3 model B+</li>
                <li> An Arduino Uno R3</li>
                <li> A Led</li>
                <li> A WiFi adapter (Edimax) for the Pi 2</li>
                <li> A USB cable with a Type A and a Type B connectors</li>
            </ul>

            <h4>Hardware setup</h4>

            <h5>1 Connect the Arduino to the Raspberry Pi</h5>
            Plug the type A connector to the Raspberry Pi and the type B connector to the Arduino
            <img src={rasparduino} className={'project-img'} alt="" />
            <br />

            <h5>2 Attach the led to the Arduino</h5>
            Connet the short leg of the led to the ground (GND) pin of the Arduino and its long leg to
            <br /> the pin number 13 of the Arduino.
            <br />
            <img src={led} className={'project-img'} alt="" />
            <br />
            <b style={{color:'red'}}>Warning:</b> If attaching the led to a breadborad, use a resistor to protect it.
            <br /> The Arduino Uno has a built-in resistor associated with the pin 13.

            {/* <br /><br />
            <h4>Project Download</h4>
            https://github.com/Sisko16/led.git
            <br /> */}
            
        </div>
    )
}

export default Led 